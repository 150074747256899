<template>
  <div class="content">
    <Header />
    <div class="info">
      <div class="plaha">
        <div class="searchForm flex ui fjcS">
          <div class="col10">
            <span>Номер</span>
            <input type="text" v-model="searchNum" />
          </div>
          <div class="col15">
            <span>Телефон</span>
            <input
              type="text"
              v-model="searchUserPhone"
  
            />
          </div>
          <div class="col20">
            <span>ФИО</span>
            <input type="text" v-model="searchUserName" />
          </div>
         <!--  <div class="col20">
            <span>Поставщик</span>
            <DropDown
              :list="sailerList"
              :selected="searchSaler"
              @dropSelect="setSaler"
            />
          </div> -->
          <div class="col15">
            <span>Дата от</span>
            <input type="date" v-model="searchDataOt" />
          </div>
          <div class="col15">
            <span>Дата до</span>
            <input type="date" v-model="searchDataDo" />
          </div>
          <div class="col10 forBtn">
            <a href="#!" class="searchBtn" @click.prevent="searchOrders"
              >Найти</a
            >
          </div>
        </div>
      </div>

      <div class="orderList">
        <div class="row header flex">
          <div class="col5 pad">№</div>
          <div class="col15 pad">Дата / время</div>
          <div class="col25 pad">Клиент</div>
          <div class="col5 pad">Кол-во</div>
          <div class="col10 pad">Сумма</div>
          <div class="col15 pad">Статус</div>
          <div class="col5 pad">#</div>
          <div class="col15 pad">Комментарий</div>
          <div class="col5 pad"></div>
        </div>

        <div
          v-for="(item, key) in ordersList.data"
          :key="key"
          class="row flex fais orderRow"
          :class="isDprClass(item.details)"
        >
          <div
            class="paymentInfo"
            v-if="Object.keys(item.payment).length !== 0"
          >
            <img
              src="../assets/ico-card-active.svg"
              v-if="item.payment.status == 0"
            />
            <img src="../assets/ico-card.svg" v-if="item.payment.status != 0" />
          </div>
          <img 
            src="../assets/elko_ico.jpg" 
            v-if="isElkoClass(item.details)"
            class="elkoIco"
          />
          <img
            src="../assets/m_ico.svg"
            v-if="item.city == 1"
            class="mariupolIco"
          />
          <img
            src="../assets/l_ico.svg"
            v-if="item.city == 2"
            class="mariupolIco"
          />
          <img
            src="../assets/z_ico.svg"
            v-if="item.city == 3"
            class="melitopolIco"
          />
          <div class="col5 pad">{{ item.id }}</div>
          <div class="col15 pad">{{ item.dat }} | {{ item.tim }}</div>
          <div class="col25 pad" :class="{ telega: item.telega }">
            {{ item.user.name }} | {{ item.user.phone }} | {{ item.user.delivery }}
          </div>
          <div class="col5 pad">{{ getCnt(item.details) }} шт.</div>
          <div class="col10 pad">{{ getPrice(item.details) }} руб.</div>
          <div class="col15 pad ui">
            <DropDown
              :list="statusList"
              :selected="item.status"
              :itemId="item.id"
              @dropSelect="setOrderStatus"
            />
          </div>
          <div class="col5 pad">
            <span class="managerComment" v-if="item.managerComment != ''"
              ><b>{{ item.managerComment }}</b></span
            >
          </div>
          <div class="col15 pad userComment">{{ item.userComment }}</div>
          <div class="col5 pad">
            <a
              href="#!"
              class="ico-edit"
              @click.prevent="this.$router.push(/order/ + item.id)"
            ></a>
          </div>
          <div class="details">
            <span v-for="(det, key) in item.details" :key="key">{{
              det.status == 0 ? det.name : ""
            }}</span>
          </div>
        </div>
      </div>
      <div class="plaha">
        <div class="pagination flex ui">
          <Pagination :totalpage="ordersList.totalPage"  link="orders"  />
          <a
            href="#!"
            class="btn"
            @click.prevent="this.$router.push({ path: '/order' })"
            >Создать заказ</a
          >
          <a href="#!" @click.prevent="isDeleteShow = 1">Удаленные заказы</a>
          
        </div>
      </div>
    </div>
  </div>
</template>


<style lang="scss" scoped>
.paymentInfo {
  position: absolute;
  top: 17px;
  left: 58%;
}
.telega {
  position: relative;
  &::before {
    position: absolute;
    display: block;
    content: "";
    width: 4px;
    height: 4px;
    background: rgb(17, 183, 248);
    top: 14px;
    left: 0px;
  }
}
.dpr {
  background-color: #e5fafd;
}
.elkoIco {
    position: absolute;
    width: 33px;
    top: 24px;
    left: -21px;
}
.orderRow {
  flex-wrap: wrap !important;
  position: relative;
  .mariupolIco {
    display: block;
    position: absolute;
    left: -3px;
    top: 12px;
    width: 15px;
    height: 17px;
  }
  .melitopolIco {
    display: block;
    position: absolute;
    left: -3px;
    top: 12px;
    width: 15px;
    height: 17px;
  }
  .details {
    width: 100%;
    padding: 2px 0 2px 15px;
    color: #a1a1a1;
    font-size: 11px;
    span {
      display: inline-block;
      margin-right: 15px;
    }
  }
}
.selectSalersForExport {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 280px;
  .dropDown {
    width: 85% !important;
  }
  a {
    margin-left: 25px;
  }
}
</style>
<script>
import api from "@/components/app/api";
import DropDown from "@/components/app/dropDown";
import Pagination from "@/components/app/pagination";
import Config from "@/components/app/config";
import Header from "@/components/app/header";
import Helper from "@/components/app/helper";
export default {
  name: "Home",
  data() {
    return {
      sailerList: Config.sailerList, //Список поставщиков
      ordersList: [], //Список заказов
      statusList: Config.statusList, //Список статусов
      searchUserPhone: "",
      searchSaler: 0,
      searchNum: "",
      searchUserName: "",
      searchDataOt: "",
      searchDataDo: "",
      isShowDropExport: false,
      isDeleteShow: 0,
    };
  },
  watch: {
    "$route.params.id": {
      handler: function () {
        this.list();
      },
      deep: true,
      immediate: true,
    },
    isDeleteShow: {
      handler: function () {
        this.list();
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    isDprClass: function (arr) {
      const callback = (elem) => {
        if (elem.isDpr) {
          return elem;
        }
      };
      return arr.some(callback) ? "dpr" : "";
    },
    isElkoClass: function (arr) {
      const callback = (elem) => {
        if (elem.elko.elkoPrice>0) {
          return true;
        }
      };
      return arr.some(callback);
    },
    exportXls(e) {
      window
        .open(
          `https://admin.vlarnika.ru/excel/export_obrabotka.php?dat_ot=${this.searchDataOt}&dat_do=${this.searchDataDo}&saler=${e.id}`,
          "_blank"
        )
        .focus();
    },
    async searchOrders() {
          
        await this.list(true);
      
    },
    parsePhoenix(e) {
      this.searchUserPhone = Helper.methods.parsePhoenix(
        e,
        this.searchUserPhone
      );
    },
    async getSalers() {
      this.sailerList = await api.methods.SALERS_list();
    },
    getCnt(arr) {
      let cnt = 0;
      arr.forEach((element) => {
        if (element.status == 0) {
          cnt = cnt + Number(element.cnt);
        }
      });
      return cnt;
    },
    getPrice(arr) {
      let price = 0;
      arr.forEach((element) => {
        if (element.status == 0) {
          price = price + Number(element.cnt * element.price);
        }
      });
      return Helper.methods.number_format(price, 0, ",", " ");
    },
    async list(isSearch = false) {
      isSearch ? this.$router.push(/orders/ + 1) : "";

      this.ordersList = await api.methods.ORDER_list({
        page:
          this.$route.params.id == undefined || isSearch
            ? 1
            : this.$route.params.id,
        searchUserPhone: this.searchUserPhone,
        searchSaler: this.searchSaler,
        searchNum: this.searchNum,
        searchUserName: this.searchUserName,
        searchDataOt: this.searchDataOt,
        searchDataDo: this.searchDataDo,
        del: this.isDeleteShow,
      });
    },
    setSaler(e) {
      this.searchSaler = e.id;
    },
    async setOrderStatus(e) {
      let response = await api.methods.ORDER_setStatus({
        order: e.itemId,
        status: e.id,
      });
      Helper.methods.warning(response, "ok");
    },
  },
  async mounted() {
    this.list();
    this.getSalers();
  },
  async created() {},
  components: {
    DropDown,
    Header,
    Pagination,
  },
};
</script>
